<template>
    <el-dialog title="预览" center width="1200px" :visible.sync="dialogPreviewVisiblePlayer" :close-on-click-modal="false"
        :append-to-body="true">
        <Tcplayer v-if="fileType == 1" ref="Tcplayer" />
        <Aliplayer v-else-if="fileType == 2" ref="Aliplayer" />
        <div v-else-if="fileType == 3" id="Vidpreview" style="width: 100%;min-height: 600px;"></div>
        <img class="preImg" v-else-if="fileType == 4" :src="imgUrl" alt="">
    </el-dialog>
</template>
<script>
import Tcplayer from './Tcplayer.vue';
import Aliplayer from './Aliplayer.vue'
export default {
    name: 'VideoDialog',
    components: {
        Tcplayer,
        Aliplayer
    },
    props: {
        dialogPreviewVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialogPreviewVisiblePlayer: this.dialogPreviewVisible,
            imgUrl:'',
            fileType:'' // 1：视频、2：音频、3：文档、4：图片、5：身份卡、6：scorm、9：压缩包
        }
    },
    watch: {
        dialogPreviewVisiblePlayer(val) {
            if (!val && this.$refs.Aliplayer) {
                this.$refs.Aliplayer.dispose();
            }
            if (!val && this.$refs.Tcplayer) {
                this.$refs.Tcplayer.dispose()
            }
        },
    },
    methods: {
        showPreviewPlayer(val,fileType) {
            this.fileType = fileType
            if(fileType == 1){
                this.previewVideo(val)
            }else if(fileType == 2){
                this.previewAudio(val)
            }else if(fileType == 3){
                this.previewApplication(val)
            }else if(fileType == 4){
                this.previewImage(val)
            }
        },
        previewVideo(id){
            // 腾讯上传的视频预览
            this.$api.resource.previewMediasInfo(id).then((res) => {
                if (res.success && res.data) {
                    this.dialogPreviewVisiblePlayer = true
                    this.$nextTick(() => {
                        this.$refs.Tcplayer.getTcplayer(res.data)
                    })
                }
            })
        },
        previewAudio(id){
            // 阿里上传的音频预览
            this.$api.resource.previewMedias(id).then((res) => {
                if (res.success && res.data) {
                    // 音频
                    const params = {
                        format: 'mp3',
                        mediaType: 'audio',
                    }
                    // 视频，音频安全相关的
                    this.curVideoUrl = res.data
                    this.dialogPreviewVisiblePlayer = true
                    this.$nextTick(() => {
                        this.$refs.Aliplayer.initPlayer(this.curVideoUrl,false, params)
                    })
                }
            })
        },
        previewApplication(id){
            // 文档预览
            this.dialogPreviewVisiblePlayer = true
            this.$api.resource.getImageOrDocUrls(id).then((res) => {
                if (res.success) {
                    this.pdfUrl = res.data
                    // 下边是安全才有的
                    // eslint-disable-next-line no-undef
                    let demo = aliyun.config({
                        mount: document.querySelector('#Vidpreview'),
                        url: res.data.previewURL, //设置文档预览URL地址。
                    })
                    console.debug('demo', demo)
                    // //设置AccessToken。
                    demo.setToken({ token: res.data.accessToken })
                    // ppt存在动画才有，否则会报错
                    if (this.fileType && this.fileType.split('/')[1] === 'ppt') {
                        demo.PPTApplication().ActivePresentation.SlideShowSettings.Run()
                        demo.PPTApplication().ActivePresentation.Slides.Count
                    }
                }
            })
        },
        async previewImage(url){
            this.dialogPreviewVisiblePlayer = true
            const params = { url }
            let data = await this.$api.operationHomeWork.getPrivateResUrl({params});
            console.log('=======data=====',data);
            this.imgUrl = data.data
        },
    },
}
</script>
<style lang="stylus" scoped>
  .resource-img
    max-width: 100%;
    max-height: 600px;
    display: inline-block;
    margin: 0 auto;
  /deep/ .el-dialog__body
    padding:24px;
  .dialog-div-img
    background: #F7FAFE;
    border: 1px solid #D6DEEA;

::v-deep {
    .el-dialog {
        border-radius: 16px;
    }

    .el-dialog__body {
        padding: 16px;
        .preImg{
            max-width: 100%
        }
    }

    .el-dialog__header {
        padding: 20px 0;
        box-shadow: inset 0px -1px 0px 0px #DFE5F1;

        .el-dialog__close {
            font-size: 22px;
            font-weight: 600;
            color: #08224D;
        }

        .el-dialog__title {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #08224D;
            line-height: 25px;
        }
    }
    .el-dialog__footer{
        border-top: 1px solid #DFE5F1;
        padding-top: 24px;
        .el-button{
            width: 103px;
            height: 42px;
            border-radius: 21px;
        }
    }
        
}
  
  </style>
  