<template>
  <div class="approve-process">
    <div class="approve-process-item" v-for="(item, index) in approveProcess" :key="index + 'approveProcess'">
      <div class="left">
        <p class="line"></p>
        <img :src="item.headImage || require('../../../assets/images/userL.png')" alt="">
      </div>
      <div class="right">
        <p class="userName">{{ item.createUser }}</p>
        <div class="statusBox">
          <!-- 审批结果 status 0 发起审批 1 通过 2 驳回  -->
          <p class="statusText" :class="approveProcessStatus[item.status].className">
            {{ approveProcessStatus[item.status].text }}
          </p>
          <p class="score" v-if="item.score">({{ item.score }}分)</p>
          <p class="time">{{ item.createTime }}</p>
        </div>
        <div class="message">{{ item.content }}</div>
        <fileList :list="item.fileList" :is-download="true"></fileList>
      </div>
    </div>
  </div>
</template>

<script>
import fileList from './fileList.vue';
export default {
  props: {
    approveProcess: {
      type: Array,
      default: () => []
    }
  },
  components:{fileList},
  data(){
    return{
      approveProcessStatus:{
        0:{
          text:'发起审批',
          className:'statusText0'
        },
        1:{
          text:'通过',
          className:'statusText1'
        },
        2:{
          text:'驳回',
          className:'statusText2'
        }
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.approve-process {
  margin-top: 24px;

  .approve-process-item {
    display: flex;
    margin-bottom: 32px;

    .left {
      margin-right: 14px;
      position: relative;

      img {
        width: 48px;
        border-radius: 50%;
        height: 48px;
        object-fit: cover;
      }

      .line {
        position: absolute;
        height: 100%;
        left: 22px;
        top: 50px;
        border: 1px dashed #DFE5F1;
      }
    }

    &:last-child {
      .left .line {
        display: none;
      }
    }

    .right {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      .userName {
        color: #08224D;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .statusBox {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        line-height: 20px;

        p {
          margin-right: 12px;
        }

        .statusText {
          color: #1A72FF;

          &.statusText2 {
            color: #FF1717;
          }

          &.statusText1 {
            color: #6DBE1A;
          }
        }

        .score {
          color: #657D9C;
        }

        .time {
          color: #657D9C;
        }
      }

      .message {
        color: #333333;
        line-height: 20px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>