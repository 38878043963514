<template>
    <div style="height:100%;position:relative;" id="tc_play_warp">
    </div>
  </template>
  
  <script>
  import $ from 'jquery'
  import TCPlayer from 'tcplayer'
  export default {
      name: 'Tcplayer',
      props: {
  
      },
      data() {
          return {
              tcplayer: null, // 播放器实例
              options: {
                  // fileID: '243791581722038521', // 请传入需要播放的视频 fileID（必须）
                  // appID: '1500020443', // 请传入点播账号的 appID（必须）
                  // psign: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUwMDAyMDQ0MywiZmlsZUlkIjoiMjQzNzkxNTgxNzM1MDkxNDk2IiwiY3VycmVudFRpbWVTdGFtcCI6MTY4MjU2NTYwOSwiY29udGVudEluZm8iOnsiYXVkaW9WaWRlb1R5cGUiOiJPcmlnaW5hbCJ9LCJleHBpcmVUaW1lU3RhbXAiOjE2ODI2MTEyMDAsInVybEFjY2Vzc0luZm8iOnsiZG9tYWluIjoiMTUwMDAyMDQ0My52b2QyLm15cWNsb3VkLmNvbSIsInNjaGVtZSI6IkhUVFBTIn19.3zkXP4Cs-NxK9OKZ_4v1RsztZtCWDNbzXmChJmfEId8',
                  autoplay: true,
                  // fakeFullscreen: true,
                  controlBar: {
                      progressControl: true
                  }
              },
          }
      },
      destroyed() {
      },
      created() {
  
      },
      beforeDestroy() {},
      methods: {
          dispose() {
            // 关闭窗口  关闭播放。
            if (this.tcplayer) {
              this.tcplayer.dispose()
            }
          },
          getTcplayer(videoInfo) {
              const timestamp = Date.now()
              $('#tc_play_warp').children().remove()
              const cover = `<video id="player-container-id_${timestamp}" style="width:100% !important; height:600px !important;" preload="auto" playsinline webkit-playsinline></video>`
              $('#tc_play_warp').append(cover)
              const options = Object.assign({}, this.options)
              // if (this.tcplayer) {
              //   this.tcplayer.dispose()
              // }
              if (videoInfo.tencentPlayVideoResp.fileId) {
                options.fileID = videoInfo.tencentPlayVideoResp.fileId
                options.appID = videoInfo.tencentPlayVideoResp.appId
                options.psign = videoInfo.tencentPlayVideoResp.psign
              }
              if (videoInfo.tencentPlayVideoResp.playUrl) {
                options.sources = [{src: videoInfo.tencentPlayVideoResp.playUrl}]
              }
              this.tcplayer = TCPlayer(`player-container-id_${timestamp}`, options)
              
              this.tcplayer.on('ready', async () => {
                
              })
  
              this.tcplayer?.on('play', async () => {
                
              })
              this.tcplayer?.on('fullscreenchange', async (e) => {
                const isFullscreen = this.tcplayer.isFullscreen()
                let video = document.querySelector('video')
                if (isFullscreen) {
                  video.style.height = '100%'
                } else {
                  e.target.style.width = '100%'
                  e.target.style.height = '610px'
                  video.style.height = '610px'
                }
              })
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep .player-container-id-dimensions {
    width: 100%;
  }

  ::v-deep .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    border: 0;
    background-color: transparent;
    transform: translateX(56px)
  }

  ::v-deep .video-js .vjs-progress-control {
    top: 0;
    overflow: visible;
  }
  </style>
  