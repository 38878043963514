<template>
  <div class="fileList" :class="className">
    <div class="fileItemWrapper" v-for="(item, index2) in list" :key="item.id">
      <div class="fileItem" :class="classItemName"  @click="downLoad(item)">
        <img v-if="['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(item.type)"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/work-img-icon.png" class="icon" alt="">
        <img v-if="['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'].includes(item.type)"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/work-file-icon.png" class="icon" alt="">
        <img v-if="['mp4', 'avi', 'wmv', 'mov', 'flv', 'rmvb', '3gp', 'm4v', 'mkv'].includes(item.type)"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/work-video-icon.png" class="icon" alt="">
        <img v-if="['zip', 'rar', 'tar'].includes(item.type)"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/work-zip-icon.png" class="icon" alt="">
        <div class="attachment-title">
          <div class="resTitle">
            <p class="name">{{ item.name }}</p>
            <p class="type">.{{ item.type }}</p>
          </div>

          <p class="size" v-if="item.size && isSize">{{ formatBytes(item.size,0) }}</p>
        </div>
        <img v-if="isDownload" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/work-download-icon.png"
          class="downloadIcon" alt="">
        <img v-if="[0, 1, 3].includes(status) && isDel"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/work-del-icon.png" class="delIcon"
          @click="del(index2)" alt="">
      </div>
      <el-button type="text" v-if="isShowPreview(item)" @click="showPreview(item)">预览</el-button>
    </div>
    <!--资源查看-->
    <VideoDialog ref="videodialog" :dialogPreviewVisible="dialogPreviewVisible"/>
  </div>
</template>

<script>
import VideoDialog from './VideoDialog.vue'
export default {
  components:{ VideoDialog },
  data(){
    return{
      dialogPreviewVisible:false
    }
  },
  props: {
    className: {
      type: String
    },
    classItemName: {
      type: String
    },
    isDownload: {
      type: Boolean,
      default: false
    },
    isDel: {
      type: Boolean,
      default: false
    },
    isSize:{
      type: Boolean,
      default: true
    },
    list: {
      type: Array,
      default: () => []
    },
    status: {
      type: Number,
      default: 0
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 是否可以预览
    isShowPreview(item) {
      if(this.isPreview){
        if(this.isVideo(item._type) || this.isAudio(item._type) || this.isApplication(item.type) || this.isImage(item._type)){
          return true
        }else{
          return false
        }
      }else{
        return false
      } 
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    del(index) {
      console.log('this.list', this.list);

      this.list.splice(index, 1)
    },
    downLoad(item){
      if(item._type){
        if(this.isVideo(item._type)){ //视频
          this.downLoadFile1(item)
        } else if(item.type === 'pdf'){ //pdf
          this.downLoadFile3(item)
        }  else if(this.isAudio(item._type) || this.isApplication(item.type)){// 音频、文档
          this.downLoadFile2(item)
        }else{
          this.downLoadFile(item)
        }
      }
    },
    downLoadFile1(item) {
      this.$api.resource.getDownloadUrl({id: item.id}).then(res => {
        if (res.data) {
          let a = document.createElement("a");
          a.download = res.data.fileName;
          a.href = res.data.downloadUrl + `&download_name=${res.data.fileName}`;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      })
    },
    downLoadFile2(item) {
      this.$api.resource.downFile(item.id).then(res => {
        if (res.data) {
          let fileUrl = res.data.fileUrl;
          let a = document.createElement("a");
          a.download = res.data.fileName;
          a.href = fileUrl;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      });
    },
    downLoadFile3(item) {
      this.$api.resource.downFile(item.id).then(res => {
        if (res.data) {
          let fileUrl = res.data.fileUrl;
          // console.log("fileUrl", fileUrl);

          var xhr = new XMLHttpRequest();
          xhr.open("GET", fileUrl, true);
          xhr.responseType = "blob";
          xhr.onload = function(e) {
            // console.debug('e',e)
            if (e.target.readyState === 4 && e.target.status === 200) {
              let blob = this.response;
              // console.debug("blob", blob);
              // 转换一个blob链接
              let u = window.URL.createObjectURL(blob);
              // console.debug(u);
              let a = document.createElement("a");
              a.download = res.data.fileName;
              a.href = u;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(u);
            }
          };
          xhr.send();
        }
      });
    },
    // 下载附件
    async downLoadFile(item) {
      if (!this.isDownload) {
        return false;
      }
      console.log('item', item);

      const params = {
        url:item.url
      }
      let data = await this.$api.operationHomeWork.getPrivateResUrl({params});
      console.log('=======data=====',data);
      

      let fileUrl = data.data;
      console.log('fileUrl',fileUrl);
        var xhr = new XMLHttpRequest();
        xhr.open("GET", fileUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function (e) {
          // console.debug('e',e)
          if (e.target.readyState === 4 && e.target.status === 200) {
            let blob = this.response;
            // console.debug("blob", blob);
            // 转换一个blob链接
            let u = window.URL.createObjectURL(blob);
            // console.debug(u);
            let a = document.createElement("a");
            a.download = item.name;
            a.href = u;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(u);
          }
        };
        xhr.send();
    },
    // 预览附件
    showPreview(item) {
      if(!item._type) return
      this.dialogPreviewVisible = true;
      if(this.isVideo(item._type)){
        this.$refs.videodialog.showPreviewPlayer(item.id,1);
      }else if(this.isAudio(item._type)){
        this.$refs.videodialog.showPreviewPlayer(item.id,2);
      }else if(this.isApplication(item.type)){
        this.$refs.videodialog.showPreviewPlayer(item.id,3);
      }else if(this.isImage(item._type)){
        this.$refs.videodialog.showPreviewPlayer(item.url,4);
      }
    },
    isImage(type) {
      let isImage = /^image\//
      return isImage.test(type)
    },
    isApplication(type){
      // let isApplication = /^application\//
      // return isApplication.test(type)
      let suffixArr = ['doc','docx','xls','xlsx','ppt','pptx','pdf']
      return suffixArr.includes(type)
    },
    isVideo(type){
      let isVideo = /^video\//
      return isVideo.test(type)
    },
    isAudio(type){
      let isAudio = /^audio\//
      return isAudio.test(type)
    }
  },
}
</script>

<style lang="scss" scoped>
.fileList{
  display: flex;
  flex-wrap: wrap;
  flex: 1;

}

.fileItemWrapper{
  display:flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
}
.fileItem{
  display: flex;
  align-items: center;
  margin-right: 16px;
  padding: 11px 12px;
  width: 255px;
  box-sizing: border-box;
  background: rgba(238,244,255,0.5);
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #F0F4FA;
  .attachment-title{
    flex: 1;
    overflow: hidden;
    margin-right: 8px;
    
  }
  .resTitle{
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    flex: 1;
    overflow: hidden;
    margin-right: 8px;
  }
  .icon{
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .name{
    margin-bottom: 2px;
    @include textOverflow(auto)
    
  }
  .size{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #657D9C;
    line-height: 12px;
  }
  .downloadIcon{
    width: 12px;
    margin-left: auto;

  }
}
</style>